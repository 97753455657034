import How_To_Step from "./How_To_Step";
import { useTranslation } from "next-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { setScreen, setIsBlurred } from '../../store/slices/walkthroughSlice';

export default function How_To({ onNext, content }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');


    const goNextPage = () => {
      setTimeout(() => {
          window.scroll({ top: -1, left: 0, behavior: "smooth" });      
      }, 10); 
    
        dispatch(setScreen("B"));
    };
    return (
      <>
        <div className="how-to pt-10 px-5 md:px-10 lg:pt-20 text-center md:mb-10 lg:mb-16">
          <h2 className="text-3xl mb-2 lg:text-[80px]/[88px] lg:mb-20">{ t('homepage.steps.title') }</h2>
            <div className="how-to-steps md:flex items-start justify-center">
                <How_To_Step image="/images/icons/guide_step-2.png" title={ t('homepage.steps.items.0.label') } text={ t('homepage.steps.items.0.description') } />
            <div className="how-to-divider"></div>
                <How_To_Step image="/images/icons/guide_step-1.png" title={ t('homepage.steps.items.1.label') } text={ t('homepage.steps.items.1.description') } />
            <div className="how-to-divider"></div>
                <How_To_Step image="/images/icons/guide_step-3.png" title={ t('homepage.steps.items.2.label') } text={ t('homepage.steps.items.2.description') } />
            </div>
        </div>   
        <div className="flex items-center justify-center">
          <button onClick={ goNextPage } className="btn">{ t('homepage.steps.button_text') }</button>
        </div>
      </>
    );
}
