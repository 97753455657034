import { useTranslation } from "next-i18next";
import YouTubePlayer from "./YoutubeVideo";

const handleCreateMessage = () => {
  setCurrentStep('B');
  window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional for smooth scrolling
    });
};

export default function How_To({ onNext }) {
  const { t } = useTranslation('common');
  return (
      <>
        <div className="how-to pt-10 px-5 md:px-10 lg:pt-20 text-center md:mb-10 lg:mb-16">
          <h2 className="text-3xl mb-2 lg:text-[80px]/[88px] lg:mb-20">{ t('homepage.video.title')}</h2>
          <YouTubePlayer videoId={t('homepage.video.url')} />
        </div>  
      </>
    );
}
