import { useSelector, useDispatch } from 'react-redux';
import { setScreen } from '../store/slices/walkthroughSlice';

import Screen_CZ_A_start from './walkthrough/Screen_CZ_A_start';
import Screen_CZ_B_form from './walkthrough/Screen_CZ_B_form';
import Screen_CZ_C_message from './walkthrough/Screen_CZ_C_message';
import Screen_CZ_C_preview from './walkthrough/Screen_CZ_C_preview';


export default function WalkthroughCZ({}) {
    const currentScreen = useSelector((state) => state.walkthrough.currentScreen);
    const dispatch = useDispatch();

    const handleSetScreen = (screen) => {
        dispatch(setScreen(screen));
    };
    
    switch (currentScreen) {
        default:
            return <Screen_CZ_A_start onNext={() => handleSetScreen('B')} />;
        case 'B':
            return <Screen_CZ_B_form onNext={() => handleSetScreen('C')} />;
        case 'C':
            return <Screen_CZ_C_preview />;
    }
}