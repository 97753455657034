class VideoService {
    constructor() {
        this.videoBlob = null;
    }

    setVideoBlob(blob) {
        this.videoBlob = blob;
    }

    getVideoBlob() {
        return this.videoBlob;
    }
}

const videoService = new VideoService();
export default videoService;