// components/walkthrough/Screen_DE_C_preview.js
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import MilkaScene from '../../external/yord-santa/src/modules/scenes/MilkaScene';
import { setScreen } from '../../store/slices/walkthroughSlice';
import { setCzech } from '../../external/yord-santa/src/modules/slices/languageSlice';
import { BrowserView, isIOS, isMobile, MobileView} from 'react-device-detect';
import { resetPhotoEditor, setPhotoTaken } from '../../store/slices/photoEditorSlice';
import { resetVideoEditor, setVideoTaken } from '../../store/slices/videoEditorSlice';
import { changeCameraTargetToSanta, changeCameraTargetToSide } from '../../external/yord-santa/src/modules/slices/cameraSlice';
import { uploadFiles } from '../../utils/uploadFiles';
import audioService from '../../utils/audioService';
import videoService from '../../utils/videoService';
import LoadingOverlay from '../../external/yord-santa/src/modules/views/LoadingOverlay';
import { changeCameraModeToOrbit, lockCamera, unlockCamera } from '../../external/yord-santa/src/modules/slices/cameraSlice';
import Debug from '../../external/yord-santa/src/modules/components/Debug';
import { setHasWon } from '../../external/yord-santa/src/modules/slices/collectiblesSlice';
import { useTranslation } from 'next-i18next';
import { testSanta } from '../../external/yord-santa/src/modules/slices/santaFigureSlice';
import { setIsIOSReady, setIsSupportedBrowser, setShowOverlay, setStartedOnSafariIOS, setPlayAgainSafari } from '../../store/slices/dataMessageSlice';

export default function Screen_DE_C_preview() {
    const dispatch = useDispatch();
    const isPhoto = useSelector((state) => state.walkthrough.messageType) === "photo" ? true : false;
    const dimensions = useSelector((state) => state.photoEditor.dimensions);
    const maskDataUrlPhoto = useSelector((state) => state.photoEditor.maskDataUrl);
    const maskDataUrlVideo = useSelector((state) => state.videoEditor.maskDataUrl);
    const messageAudio = useSelector((state) => state.walkthrough.messageAudio);
    const maskDataUrl = isPhoto ? maskDataUrlPhoto : maskDataUrlVideo;
    
    const photoDataUrl = useSelector((state) => state.photoEditor.photoDataUrl);
    const [videoSrc, setVideoSrc] = useState('');
    // const [showOverlay, setShowOverlay] = useState(false);
    const timerRef = useRef(null);
    const [currentStep, setCurrentStep] = useState('a');
    const [uploadStatus, setUploadStatus] = useState(null)
    const [contentURL, setContentURL] = useState('');
    const [contentLink, setContentLink] = useState('');
    const isStarted = useSelector((state) => state.user.isStarted);
    const [showContinueBtn, setShowContinueBtn] = useState(true);
    const [playVideo, setPlayVideo] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [handleCopyPopup, setHandleCopyPopup] = useState(false);

    const dataPhonems = useSelector((state) => state.dataMessage.phonems);
    const dataHash = useSelector((state) => state.dataMessage.hash);
    const iosReady = useSelector((state) => state.dataMessage.isIOSReady);
    const lang = useSelector(state => state.dataMessage.lang);
    const showOverlay = useSelector(state => state.dataMessage.showOverlay);
    const isSupportedBrowser = useSelector(state => state.dataMessage.isSupportedBrowser);
    const fullPath = useSelector(state => state.dataMessage.fullPath);



    const audioRef = useRef(null);

    const { t } = useTranslation('common');

    useEffect(() => {
        isSafariOrIOS() ?? dispatch(setIsSupportedBrowser(false)); 

        setContentLink( dataHash );
        dispatch(setCzech());
        dispatch(setHasWon());
        dispatch(changeCameraModeToOrbit());

        if( !isSafariOrIOS() ) {
            setTimeout(() => {
                dispatch(changeCameraTargetToSanta());
            }, 750);

            setTimeout(() => {
                playSanta();            
            }, 2000);
        }
    }, []);
    
    const isSafariOrIOS = () => {
        if (typeof navigator === "undefined") return false; // Check if navigator is available (to avoid SSR issues)
        
        const userAgent = navigator.userAgent;
        const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent); // Check for Safari, excluding Chrome
        const isIOS = /iPad|iPhone|iPod/.test(userAgent); // Check for iOS device
        if( isSafari || isIOS) {
            dispatch(setIsSupportedBrowser(false)); 
        }
        return isSafari || isIOS; // Return true if Safari or iOS, otherwise false
    };

    const playSanta = () => {
        dispatch(testSanta(true));
    }

    useEffect(() => {
            if( showOverlay ) {
                handleBtnContinue();
            }
    }, [showOverlay]);

    const handlePlayAgain = () => {
        dispatch(testSanta(false));
        dispatch(setShowOverlay(false));
        if( !isSafariOrIOS() ) {
            setTimeout(() => {
                dispatch(changeCameraTargetToSanta());
            }, 500);
            setTimeout(() => {
                playSanta();
            }, 1500);
        }
        else {
            dispatch(setPlayAgainSafari(true));
        }
    }

    const handleBtnContinue = () => {
        dispatch(changeCameraTargetToSide());
        setTimeout(() => {
            dispatch(setShowOverlay(true));
        }, 750);
        setShowContinueBtn(false);
        setPlayVideo(false);
        if (messageAudio) {
            audioService.stopAudio();
        }
    };

    const handlePlayClick = () => {
        dispatch(setShowOverlay(false));
        setShowContinueBtn(true);
        setPlayVideo(true);
        // if (messageAudio) {
        //     audioService.playAudio();
        // }
    };

    const handleCreateAnotherMessage = () => {
        window.location.href = `${process.env.NEXT_PUBLIC_URL}`;
    };

    const handleSendMessage = async () => {
        setCurrentStep('b');
    };

    const handleCopyLink = () => {
        setHandleCopyPopup(true);
    
        setTimeout(() => {
            setHandleCopyPopup(false);
        }, 2000);

        // Check if Clipboard API is supported
        if (navigator.clipboard) {
            navigator.clipboard.writeText(`${fullPath}/message/${contentLink}`)
                .then(() => {
                    console.log("Text copied successfully!");
                })
                .catch((error) => {
                    console.error("Failed to copy text: ", error);
                    fallbackCopyText(`${fullPath}/message/${contentLink}`);
                });
        } else {
            // Fallback for browsers that don't support Clipboard API
            console.log("Clipboard API not supported, using fallback method.");
            fallbackCopyText(`${fullPath}/message/${contentLink}`);
        }
    };
    
    // Fallback method using document.execCommand('copy')
    const fallbackCopyText = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.setAttribute('readonly', '');  // Prevents screen keyboard on iOS
        textArea.style.position = 'absolute';
        textArea.style.left = '-9999px';
        textArea.style.top = '0';
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    
        console.log("Fallback: Text copied using execCommand.");
    };

    const handleToggleMute = () => {
        setIsMuted(!isMuted);
    };

    return (
        <div className="preview" id="root">
            {/* <div className="preview-icon_360"></div> */}


            {!showOverlay && (
                <div className="preview-control">
                    {showContinueBtn && (
                        <>
                        {/* <button 
                            className="btn btn-weiter" 
                            onClick={handleBtnContinue}
                        >NEXT</button> */}
                        {/* <button className='btn' onClick={playSanta}>
                            PLAY
                        </button> */}
                        </>
                    )}
                </div>
            )}
            <Debug/>
            <div className={ showOverlay ? 'hide-next' : ''}></div>
            <MilkaScene lang={ lang }>
                <div  style={{ position: 'relative', width: dimensions.width, height: dimensions.height }}>
                    
                </div>
            </MilkaScene>
            
            <LoadingOverlay />
            {showOverlay && (
                <div className="preview-overlay fade-in" data-step={currentStep}>
                    {/* <a className="preview-overlay-logo" href="#">
                        <img src="/images/milka.png" alt="Milka" />
                    </a> */}
                        {currentStep === "a" && (
                            <div className='preview-overlay fade-in max-lg:!bg-none'>
                              <div className='preview-overlay-inner max-lg:w-full h-full lg:grid lg:grid-cols-[auto_358px] max-w-[1100px]'>
                                  <div className="logo pt-5 mb-20 lg:hidden">
                                          <img src="/images/milka.png" />
                                  </div>
                                  <div className='preview-bubble max-lg:!bg-none max-lg:!px-0 max-lg:!aspect-auto max-lg:mx-auto self-center flex flex-col justify-center items-center lg:!w-[460px] max-lg:!w-full max-lg:max-w-[460px] max-lg:!bg-[size:420px]'>
                                    <span className="text-[32px]/[36px] mb-2.5 font-semibold text-center">{ t('bubble.message.text') }</span>
                                        <div className="preview-buttons_wrapper_top grid grid-cols-2 w-full gap-5 lg:max-w-[320px]">
                                            <button className="btn" onClick={() => dispatch(setScreen('B')) }>
                                            { t('bubble.message.edit') }
                                            </button>
                                            <button className="btn" onClick={handlePlayAgain}>
                                            { t('bubble.message.play') }
                                            </button>
                                        </div>
                                        <div className="preview-buttons_wrapper_bottom w-full mt-5 lg:max-w-[320px]">
                                            <button
                                                className="btn btn-gold w-full"
                                                onClick={handleSendMessage}
                                            >
                                                { t('bubble.message.send') }
                                            </button>
                                        </div>
                                  </div>
                                  <div className='preview-image shrink-0 max-lg:pl-14 max-lg:mt-4 max-lg:max-h-[719px] h-full flex items-end justify-center lg:overflow-hidden max-lg:relative'>
                                      <img className="max-lg:mx-auto max-h-[90svh] max-lg:hidden" src='/images/preview-santa.png' />
                                      <img className="max-lg:mx-auto max-lg:absolute max-lg:top-0 lg:hidden" src='/images/santa_b.png' />
                                  </div>
                              </div>
                          </div>
                        )}
                        {currentStep === "b" && (
                            <>
                                <div className='preview-overlay fade-in max-lg:!bg-none overflow-autotext-center lg:flex lg:flex-col lg:items-center lg:justify-center overflow-auto'>
                                    <div className='preview-overlay-inner max-lg:w-full h-full lg:grid lg:grid-cols-[auto_358px] max-w-[1100px]'>
                                        <div className="logo pt-5 mb-20 lg:hidden">
                                            <img src="/images/milka.png" />
                                        </div>
                                        <div className='text-center lg:flex lg:flex-col lg:items-center lg:justify-center pb-10 lg:py-[120px]'>
                                            <p className="h1 !text-3xl lg:!text-[60px]/[66px] font-extrabold mb-6">{ t('bubble.share.text') }</p>
                                            <p className="text-base lg:text-2xl mb-6">{ t('bubble.share.description') }</p>
                                            <div className="divider"></div>
                                            {/* <p className="text-big mb-[1em] shrink"><strong>Share message via</strong></p> */}
                                            <div className="preview-message-share">
                                                { isMobile && <a target='_blank' href={`fb-messenger://share/?link=${fullPath}/message/${contentLink}`}>
                                                    <img src="/images/icons/icon-share-facebook.png" />
                                                </a> }
                                                {/* fb share desktop */}
                                                {/* { !isMobile && <a target='_blank' href={`https://www.facebook.com/dialog/send?app_id=554095150496957&link=${contentLink}&redirect_uri=${contentLink}`}>
                                                    <img src="/images/icons/icon-share-facebook.png" />
                                                </a>} */}
                                                <a target='_blank' href={`mailto:?subject=${t('head.app_message')}&body=${fullPath}/message/${contentLink}`}>
                                                    <img src="/images/icons/icon-share-email.png" />
                                                </a>
                                                <a target='_blank' href={`https://api.whatsapp.com/send?text=${t('head.app_message')} ${fullPath}/message/${contentLink}`}>
                                                    <img src="/images/icons/icon-share-whatsapp.png" />
                                                </a>
                                                {/* { isMobile && <button onClick={ showSharer() }>
                                                    <img src="/images/icons/icon-share-all.png" />
                                                </button> } */}
                                            </div>
                                            <div className="preview-message-buttons">
                                                {contentURL && (
                                                    <Link
                                                        className="btn preview-button btn-download"
                                                        target="blank"
                                                        href={contentURL}
                                                    >
                                                        <span>Herunterladen</span>
                                                        <img src="/images/icons/icon-download.png" />
                                                    </Link>
                                                )}
                                                {/* {contentLink && ( */}
                                                    <div className='relative'>
                                                    <button
                                                        className="btn preview-button btn-copy_link"
                                                        onClick={() => {
                                                            handleCopyLink();
                                                        }}
                                                    >
                                                        <span>{ t('bubble.share.copy_link') }</span>
                                                        <img src="/images/icons/icon-copy.png" />
                                                    </button>
                                                    { handleCopyPopup && (
                                                        <div className='copy-popup'>
                                                            {/* <span>Kopiert</span> */}
                                                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                                        </div>
                                                    )}
                                                    </div>
                                                {/* )} */}
                                            </div>
                                            <div className="divider"></div>
                                                <button
                                                    className="btn btn-milka_mid"
                                                    onClick={handleCreateAnotherMessage}
                                                >
                                                    { t('bubble.share.start_again') }
                                                </button>
                                        </div>
                                        <div className='max-lg:hidden preview-image shrink-0 max-lg:pl-14 max-lg:mt-4 max-lg:max-h-[719px] h-full flex items-end justify-center lg:overflow-hidden max-lg:relative'>
                                            <img className="max-lg:mx-auto max-h-[90svh] max-lg:hidden" src='/images/preview-santa.png' />
                                            <img className="max-lg:mx-auto max-lg:absolute max-lg:top-0 lg:hidden" src='/images/santa_b.png' />
                                        </div>
                                    </div>
                                </div>
                            </>

                        )}
                    {/* <div className="preview-santa"></div> */}
                </div>
            )}
            <div className="preview-logo">
                <a className="logo" href="#">
                    <img src="/images/milka.png" alt="Milka" />
                </a>
            </div>
            {/* <button
                className={`preview-mute_btn ${isMuted ? 'sound-off' : 'sound-on'}`}
                onClick={handleToggleMute}
            >
            </button> */}
            <div style={{ display: 'none' }}>
                <a href={photoDataUrl} download="original_photo.png" id="original-photo-link">Download Original Photo</a>
                {maskDataUrl && (
                    <a href={maskDataUrl} download="mask_image.png" id="mask-image-link">Download Mask Image</a>
                )}
            </div>
        </div>
    );
}
