import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import WalkthroughCZ from '../components/WalkthroughCZ';
import { useDispatch } from 'react-redux';
import { setLang, setFullPath } from '../store/slices/dataMessageSlice';
import Head from 'next/head';



export default function Home({ lang, fullPath }) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();  

  useEffect(() => {
    dispatch(setLang(lang));
    dispatch(setFullPath(fullPath));
  }, [lang]);

  return (
    <>
      <Head>
        <title>{ t('head.title') }</title>
        <meta name="description" content={ t('head.title') }/>
        <meta property="og:title" content={ t('head.title') } />
        <meta property="og:description" content={ t('head.description') } />
        <meta property="og:image" content={`${process.env.NEXT_PUBLIC_URL}/images/og-image.jpg`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Head>
      <WalkthroughCZ />
    </>
  );
}

export async function getServerSideProps({ req, res }) {
  const host = req.headers.host;
  const hostnameParts = host.split('.');
  const tld = hostnameParts.pop();
  const defaultLang = 'cs';
  let fallbackLang;

  const tldToLangMap = {
    hu: 'hu',
    cz: 'cs',
    sk: 'sk',
  };

  if( host ) {
    fallbackLang = tldToLangMap[tld] || defaultLang;
  }

  
  const lang = fallbackLang;
  const fullPath = host;
  return {
    props: {
      lang,
      fullPath,
      ...(await serverSideTranslations(lang, ['common'])),
    },
  };
}
