import How_To from "../snippets/How_To";
import VideoSection from "../snippets/VideoSection";
import { useSelector, useDispatch } from 'react-redux';
import { setScreen, setIsBlurred } from '../../store/slices/walkthroughSlice';
import { useTranslation } from 'next-i18next';

export default function Screen_CZ_A_start({ onNext }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const goNextPage = () => {
        dispatch(setScreen("B"));
    };

    return (
        <>
        <div className="screen_cs_intro">
            <div className="lg:grid lg:grid-cols-[auto_1fr] lg:items-center lg:justify-center lg:h-full lg:min-h-[600px] lg:overflow-hidden max-w-[1920px] lg:mx-auto">
                <div className="screen_cs_intro-content lg:!py-[140px]">
                    <p className="title-before">{ t('homepage.header.beforeTitle')}</p>
                    <h1 className="h1">{ t('homepage.header.title')}</h1>
                    <div className="circle">{ t('homepage.header.circle')}</div>
                    <div className="content-divider"></div>
                    <p className="text">{ t('homepage.header.subtitle')}</p>
                    <button onClick={ goNextPage } className="btn">{ t('homepage.header.button_text')}</button>
                </div>
                <div className="self-end max-lg:hidden lg:flex lg:h-full lg:max-h-[1080px] overflow-hidden">
                    <img className="object-cover object-left-bottom h-full w-full" src="/images/santa-big.png" />
                </div>
            </div>
        </div>
        <How_To />
        <VideoSection />
        </>
    );
}