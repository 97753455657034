import { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';

export default function YouTubePlayer({ videoId }) {
  const playerRef = useRef(null);
  const { t } = useTranslation('common');
  
  useEffect(() => {
    // Load the YouTube IFrame Player API script
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    // Initialize the YouTube player once the API is ready
    window.onYouTubeIframeAPIReady = () => {
      new window.YT.Player(playerRef.current, {
        videoId, // The YouTube video ID passed as a prop
        width: false,
        height: false,
        src: false,
        playerVars: {
          autoplay: 0,
          controls: 1,
        },
        events: {
          onReady: (event) => {
            // Add the class to the iframe after the player is ready
            const iframe = event.target.getIframe();
            iframe.classList.add('optanon-category-C0004');
          },
        },
      });
    };

    // Cleanup the script and window event handler when the component unmounts
    return () => {
      delete window.onYouTubeIframeAPIReady;
    };
  }, [videoId]);

  return (
    <div className="video-wrapper">
      {/* The YouTube player will replace this div */}
      <div ref={playerRef}></div>
    </div>
  );
}